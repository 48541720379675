import React, { useState, useEffect } from "react";
import axios from "axios";
import "./Login.css";

function Login({ setLoggedIn, setToken, setPlbToken, setUserId }) {
  const [username, setUsername] = useState("");
  const [password, setPassword] = useState("");
  const [errorMessage, setErrorMessage] = useState("");

  useEffect(() => {
    localStorage.removeItem('token');
    localStorage.removeItem('username');
    localStorage.removeItem('plbToken');
    // setLoggedIn(false);
    
    // If you can access setMenuOpen, you could pass it as a prop and use it here
    // setMenuOpen(false);
  }, []);

  const fetchRegisteredHours = async (token, userId) => {
    try {
        /*const response = await axios.get("https://fmhyper.com/filemaker-api.php?action=get-registered-hours", {*/
        const response = await axios.get("https://fmhyper.com/filemaker-api.php?action=get-registered-hours", {
            params: { token, userId },
            headers: { "Content-Type": "application/json" }
        });

        console.log("Registered Hours:", response.data);

        if (response.data.success) {
            localStorage.setItem("registeredHours", JSON.stringify(response.data.hours));  // ✅ Store registered hours
        } else {
            console.error("Failed to fetch registered hours:", response.data.message);
        }
    } catch (error) {
        console.error("Error fetching registered hours:", error);
    }
};

  const handleLogin = async (e) => {
    e.preventDefault();

    try {
        const response = await axios.post(
            // "https://fmhyper.com/filemaker-api.php?action=login", 
            "https://fmhyper.com/filemaker-api.php?action=login", 
            { username, password },  
            { headers: { "Content-Type": "application/json" } }
          );

      console.log("PHP Response:", response.data);

      if (response.data.success) {
        setToken(response.data.filemaker_response.tokenAgenda);
        
        if (typeof setPlbToken === "function") {  
          setPlbToken(response.data.filemaker_response.tokenPlb);  
        } else {
          console.warn("setPlbToken is undefined, cannot set PLB token.");
        }

        setUserId(username);
        setLoggedIn(true);
        setErrorMessage("");

        localStorage.setItem("token", response.data.filemaker_response.tokenAgenda);
        localStorage.setItem("plbToken", response.data.filemaker_response.tokenPlb);
        localStorage.setItem("username", username);

      } else {
        setErrorMessage(response.data.message || "Invalid credentials.");
      }
    } catch (error) {
      console.error("Login failed", error);
      setErrorMessage("Login failed. Please check your credentials.");
    }
  };

  const checkToken = async (token) => {
    try {
      const response = await axios.get("https://fmhyper.com/filemaker-api.php", {
        params: {
          action: "verify-token",
          token
        }
      });
      return response.data.success;
    } catch (error) {
      return false;
    }
  };
  
  // Add this useEffect in your Login component
  useEffect(() => {
    const token = localStorage.getItem('token');
    if (token) {
      checkToken(token).then(isValid => {
        if (!isValid) {
          // Clear invalid token
          localStorage.removeItem('token');
          localStorage.removeItem('username');
          setLoggedIn(false);
        }
      });
    }
  }, []);

  return (
    <div className="login-container">
      <form onSubmit={handleLogin} className="login-form">
        
        {/* Logo with background and animation */}
        <div className="login-logo-container">
          <img src="https://cialona.nl/wp-content/uploads/2025/01/Logo_EN_Horizontal_DIAP.svg" alt="Cialona Logo" className="login-logo" />
        </div>

        <h2>Uren Registratie</h2>

        <div className="input-group">
          <label htmlFor="username">Username</label>
          <input
            type="text"
            id="username"
            value={username}
            onChange={(e) => setUsername(e.target.value)}
            placeholder="Gebruikersnaam"
          />
        </div>

        <div className="input-group">
          <label htmlFor="password">Password</label>
          <input
            type="password"
            id="password"
            value={password}
            onChange={(e) => setPassword(e.target.value)}
            placeholder="Wachtwoord"
          />
        </div>

        {errorMessage && <p className="error-message">{errorMessage}</p>}

        <button type="submit" className="login-button">Login</button>
      </form>
    </div>
  );
}

export default Login;