import React, { useState, useEffect, useMemo } from "react";
import axios from 'axios'; 
import { Sun, Clock, AlertCircle, Calendar, ArrowDownCircle, ArrowUpCircle } from 'lucide-react';
import "./Vacation.css";

let BarChart, Bar, XAxis, YAxis, CartesianGrid, Tooltip, Legend, ResponsiveContainer;
try {
  const recharts = require('recharts');
  BarChart = recharts.BarChart;
  Bar = recharts.Bar;
  XAxis = recharts.XAxis;
  YAxis = recharts.YAxis;
  CartesianGrid = recharts.CartesianGrid;
  Tooltip = recharts.Tooltip;
  Legend = recharts.Legend;
  ResponsiveContainer = recharts.ResponsiveContainer;
} catch (err) {
  console.warn("Recharts is not available. Using placeholder charts.");
}

const Vacation = ({ userId, token, setToken, onTokenError }) => {
  const [vacationData, setVacationData] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [activeLeaveType, setActiveLeaveType] = useState(null);
  const [currentYear, setCurrentYear] = useState(new Date().getFullYear());
  const [chartsAvailable, setChartsAvailable] = useState(!!BarChart);

  // Prevent duplicate API calls with a ref
const isMountedRef = React.useRef(false);

const fetchVacationHours = async () => {
    try {
      setLoading(true);
      console.log("Fetching vacation hours data for user:", userId);
      
      // Use the get-vacation-hours action
      const response = await axios.get("https://fmhyper.com/filemaker-api.php", {
        params: {
          action: "get-vacation-hours",
          token,
          userId
        }
      });
      
      console.log("API response:", response.data);
    
      if (!response.data.success) {
        throw new Error(response.data.message || "Failed to fetch vacation hours");
      }
    
      // Extract the vacation data from _embedded array
      const vacationData = response.data.vacationHours._embedded;
      setVacationData(vacationData);
      
      console.log("Successfully loaded vacation data:", vacationData.length, "records");
      
      // Set active leave type to the first wettelijk verlof of current year
      const currentYearData = vacationData.filter(item => item.year === currentYear);
      const wettelijkVerlof = currentYearData.find(item => 
        item.leaveType.value.includes("wettelijk"));
      
      if (wettelijkVerlof) {
        setActiveLeaveType(wettelijkVerlof.leaveType.key);
      } else if (currentYearData.length > 0) {
        setActiveLeaveType(currentYearData[0].leaveType.key);
      }
      
    } catch (err) {
      console.error("Error fetching vacation hours:", err);
      // Handle axios error format
      const errorMessage = err.response?.data?.message || err.message;
      console.error("Error details:", err.response?.data);
      setError(errorMessage);
      
      // Temporarily disabled to prevent automatic logout during debugging
      // if (errorMessage.includes("token") || errorMessage.includes("authentication")) {
      //   onTokenError();
      // }
    } finally {
      setLoading(false);
    }
  };

  // For testing/development without API
  const loadMockData = () => {
    setLoading(true);
    const mockData = JSON.parse(`[
      {
          "year": 2021,
          "leaveType": {
              "balanceExceedsYear": true,
              "key": 1,
              "value": "Verlof (bovenwettelijk)"
          },
          "balancePreviousYear": 0.0,
          "accrual": 6.16,
          "usageTotal": 6.16,
          "usageThroughToday": 6.16,
          "usageAfterToday": 0.0,
          "balance": 0.00,
          "expires": 0.0,
          "unitType": {
              "key": 1,
              "value": "Uren"
          }
      },
      {
          "year": 2021,
          "leaveType": {
              "balanceExceedsYear": true,
              "key": 15,
              "value": "Verlof (wettelijk)"
          },
          "balancePreviousYear": 0.0,
          "accrual": 30.82,
          "usageTotal": 39.59,
          "usageThroughToday": 39.59,
          "usageAfterToday": 0.0,
          "balance": -8.77,
          "expires": 0.0,
          "unitType": {
              "key": 1,
              "value": "Uren"
          }
      },
      {
          "year": 2022,
          "leaveType": {
              "balanceExceedsYear": true,
              "key": 1,
              "value": "Verlof (bovenwettelijk)"
          },
          "balancePreviousYear": 0.00,
          "accrual": 30.40,
          "usageTotal": 17.87,
          "usageThroughToday": 17.87,
          "usageAfterToday": 0.0,
          "balance": 12.53,
          "expires": 0.0,
          "unitType": {
              "key": 1,
              "value": "Uren"
          }
      },
      {
          "year": 2025,
          "leaveType": {
              "balanceExceedsYear": true,
              "key": 15,
              "value": "Verlof (wettelijk)"
          },
          "balancePreviousYear": -3.48,
          "accrual": 152.00,
          "usageTotal": 38.00,
          "usageThroughToday": 38.00,
          "usageAfterToday": 0.0,
          "balance": 110.52,
          "expires": 0.0,
          "unitType": {
              "key": 1,
              "value": "Uren"
          }
      }]`);
    setVacationData(mockData);
    
    const currentYearData = mockData.filter(item => item.year === currentYear);
    if (currentYearData.length > 0) {
      setActiveLeaveType(currentYearData[0].leaveType.key);
    }
    
    setLoading(false);
  };

  useEffect(() => {
    // Prevent duplicate API calls on component re-renders
    if (token && userId && !isMountedRef.current) {
      isMountedRef.current = true;
      fetchVacationHours();
      // For testing without API:
      // loadMockData();
    }
  }, [token, userId]); // Adding fetchVacationHours to dependencies would cause an infinite loop

  // Get unique years from data
  const years = useMemo(() => {
    if (!vacationData.length) return [];
    const yearsSet = new Set(vacationData.map(item => item.year));
    return [...yearsSet].sort((a, b) => b - a); // Sort descending
  }, [vacationData]);

  // Get unique leave types from data for the current year
  const leaveTypes = useMemo(() => {
    if (!vacationData.length) return [];
    const currentYearData = vacationData.filter(item => item.year === currentYear);
    const uniqueTypes = [];
    const typeKeys = new Set();
    
    currentYearData.forEach(item => {
      if (!typeKeys.has(item.leaveType.key)) {
        typeKeys.add(item.leaveType.key);
        uniqueTypes.push({
          key: item.leaveType.key,
          value: item.leaveType.value
        });
      }
    });
    
    return uniqueTypes;
  }, [vacationData, currentYear]);

  // Get data for the selected leave type across years
  const selectedLeaveTypeData = useMemo(() => {
    if (!activeLeaveType || !vacationData.length) return [];
    
    return vacationData
      .filter(item => item.leaveType.key === activeLeaveType)
      .sort((a, b) => a.year - b.year) // Sort by year ascending
      .map(item => ({
        year: item.year,
        accrual: item.accrual,
        used: item.usageTotal,
        balance: item.balance,
        expires: item.expires,
        leaveType: item.leaveType.value,
        unitType: item.unitType.value
      }));
  }, [vacationData, activeLeaveType]);

  // Get current year's data for all leave types
  const currentYearAllTypesData = useMemo(() => {
    if (!vacationData.length) return [];
    
    return vacationData
      .filter(item => item.year === currentYear)
      .map(item => ({
        name: item.leaveType.value,
        accrual: item.accrual,
        used: item.usageTotal,
        balance: item.balance,
        isPositive: item.balance >= 0
      }));
  }, [vacationData, currentYear]);

  // Get detailed info for active leave type in current year
  const activeLeaveTypeInfo = useMemo(() => {
    if (!activeLeaveType || !vacationData.length) return null;
    
    return vacationData.find(
      item => item.year === currentYear && item.leaveType.key === activeLeaveType
    );
  }, [vacationData, currentYear, activeLeaveType]);

  const handleYearChange = (year) => {
    setCurrentYear(parseInt(year));
  };

  const handleLeaveTypeChange = (typeKey) => {
    setActiveLeaveType(parseInt(typeKey));
  };

  // Function to format time values
  const formatTimeToHHMM = (hours) => {
    if (!hours && hours !== 0) return "0:00";
    
    const wholeHours = Math.floor(Math.abs(hours));
    const decimalPart = Math.abs(hours) - wholeHours;
    
    // Convert decimal part to minutes
    let minutes = Math.round(decimalPart * 60);
    if (minutes === 60) {
      minutes = 0;
      wholeHours += 1;
    }
    
    const sign = hours < 0 ? "-" : "";
    return `${sign}${wholeHours}:${String(minutes).padStart(2, '0')}`;
  };

  if (loading) {
    return (
      <div className="vacation-container loading">
        <div className="loading-spinner"></div>
        <p>Verlofgegevens laden...</p>
      </div>
    );
  }

  if (error) {
    return (
      <div className="vacation-container error">
        <AlertCircle size={40} />
        <h2>Geen gegevens beschikbaar</h2>
        <p>{error}</p>
        <button onClick={fetchVacationHours} className="retry-button">
          Opnieuw proberen
        </button>
      </div>
    );
  }

  // Placeholder chart component when recharts not available
  const PlaceholderChart = ({ title }) => (
    <div className="placeholder-chart">
      <h3>{title}</h3>
      <div className="chart-placeholder">
        <p>Grafiek is niet beschikbaar.</p>
        <p>Installeer de recharts package met:</p>
        <code>npm install recharts --save</code>
      </div>
    </div>
  );

  return (
    <div className="vacation-container">
      <h1 className="page-title">
        <Sun className="title-icon" size={20} />
        Vakantie-uren Overzicht
      </h1>

      <div className="filter-controls">
        <div className="select-container">
          <label htmlFor="year-select">Jaar:</label>
          <select 
            id="year-select" 
            value={currentYear} 
            onChange={e => handleYearChange(e.target.value)}
          >
            {years.map(year => (
              <option key={year} value={year}>{year}</option>
            ))}
          </select>
        </div>
        
        <div className="select-container">
          <label htmlFor="leave-type-select">Verloftype:</label>
          <select 
            id="leave-type-select" 
            value={activeLeaveType || ''} 
            onChange={e => handleLeaveTypeChange(e.target.value)}
            disabled={!leaveTypes.length}
          >
            {leaveTypes.map(type => (
              <option key={type.key} value={type.key}>{type.value}</option>
            ))}
          </select>
        </div>
      </div>

      {/* Summary Cards */}
      <div className="summary-cards">
        <div className="summary-card">
          <div className="card-icon">
            <Clock size={24} />
          </div>
          <div className="card-content">
            <h3>Beschikbaar</h3>
            <p className={`card-value ${activeLeaveTypeInfo?.balance < 0 ? 'negative' : 'positive'}`}>
              {formatTimeToHHMM(activeLeaveTypeInfo?.balance || 0)}
            </p>
          </div>
        </div>
        
        <div className="summary-card">
          <div className="card-icon">
            <ArrowUpCircle size={24} />
          </div>
          <div className="card-content">
            <h3>Opgebouwd</h3>
            <p className="card-value">{formatTimeToHHMM(activeLeaveTypeInfo?.accrual || 0)}</p>
          </div>
        </div>
        
        <div className="summary-card">
          <div className="card-icon">
            <ArrowDownCircle size={24} />
          </div>
          <div className="card-content">
            <h3>Opgenomen</h3>
            <p className="card-value">{formatTimeToHHMM(activeLeaveTypeInfo?.usageTotal || 0)}</p>
          </div>
        </div>
      </div>

      {/* Table view for current year data */}
      <div className="table-container">
        <h2 className="table-title">Jaaroverzicht verlofuren {currentYear}</h2>
        <div className="responsive-table">
          <table>
            <thead>
              <tr>
                <th>Type</th>
                <th>Opgebouwd</th>
                <th>Opgenomen</th>
                <th>Saldo</th>
              </tr>
            </thead>
            <tbody>
              {currentYearAllTypesData.map((item, index) => (
                <tr 
                  key={index} 
                  className={activeLeaveType === item.key ? 'active-row' : ''}
                >
                  <td>{item.name.replace("Verlof (", "").replace(")", "")}</td>
                  <td>{formatTimeToHHMM(item.accrual)}</td>
                  <td>{formatTimeToHHMM(item.used)}</td>
                  <td className={item.balance < 0 ? 'negative' : 'positive'}>
                    {formatTimeToHHMM(item.balance)}
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      </div>

      {/* Charts section - conditionally rendered based on recharts availability */}
      {chartsAvailable ? (
        <>
          {/* Year Overview Chart */}
          <div className="chart-container">
            <h2 className="chart-title">Jaaroverzicht verlofuren</h2>
            <ResponsiveContainer width="100%" height={300}>
              <BarChart
                data={currentYearAllTypesData.map(item => ({
                  ...item,
                  name: item.name.replace("Verlof (", "").replace(")", "")
                }))}
                margin={{ top: 20, right: 30, left: 20, bottom: 20 }}
              >
                <CartesianGrid strokeDasharray="3 3" />
                <XAxis dataKey="name" tick={{ fontSize: 12 }} />
                <YAxis />
                <Tooltip 
                  formatter={(value, name) => [
                    `${formatTimeToHHMM(parseFloat(value))}`,
                    name === 'accrual' ? 'Opgebouwd' : 
                    name === 'used' ? 'Opgenomen' : 'Saldo'
                  ]}
                  labelFormatter={(label) => `Verloftype: ${label}`}
                />
                <Legend 
                  formatter={(value) => (
                    value === 'accrual' ? 'Opgebouwd' : 
                    value === 'used' ? 'Opgenomen' : 'Saldo'
                  )} 
                />
                <Bar dataKey="accrual" name="accrual" fill="#3b82f6" barSize={30} radius={[4, 4, 0, 0]} />
                <Bar dataKey="used" name="used" fill="#64748b" barSize={30} radius={[4, 4, 0, 0]} />
                <Bar dataKey="balance" name="balance" fill={data => data.isPositive ? "#10b981" : "#ef4444"} barSize={30} radius={[4, 4, 0, 0]} />
              </BarChart>
            </ResponsiveContainer>
          </div>

          {/* History Chart */}
          {selectedLeaveTypeData.length > 1 && (
            <div className="chart-container">
              <h2 className="chart-title">Historisch Overzicht: {selectedLeaveTypeData[0]?.leaveType}</h2>
              <ResponsiveContainer width="100%" height={300}>
                <BarChart
                  data={selectedLeaveTypeData}
                  margin={{ top: 20, right: 30, left: 20, bottom: 20 }}
                >
                  <CartesianGrid strokeDasharray="3 3" />
                  <XAxis dataKey="year" />
                  <YAxis />
                  <Tooltip 
                    formatter={(value, name) => [
                      `${formatTimeToHHMM(parseFloat(value))}`,
                      name === 'accrual' ? 'Opgebouwd' : 
                      name === 'used' ? 'Opgenomen' : 'Saldo'
                    ]}
                  />
                  <Legend />
                  <Bar dataKey="accrual" name="Opgebouwd" fill="#3b82f6" barSize={30} radius={[4, 4, 0, 0]} />
                  <Bar dataKey="used" name="Opgenomen" fill="#64748b" barSize={30} radius={[4, 4, 0, 0]} />
                  <Bar dataKey="balance" name="Saldo" fill="#10b981" barSize={30} radius={[4, 4, 0, 0]} />
                </BarChart>
              </ResponsiveContainer>
            </div>
          )}
        </>
      ) : (
        <>
          <PlaceholderChart title="Jaaroverzicht verlofuren" />
          {selectedLeaveTypeData.length > 1 && (
            <PlaceholderChart title={`Historisch Overzicht: ${selectedLeaveTypeData[0]?.leaveType}`} />
          )}
        </>
      )}
    </div>
  );
};

export default Vacation;