import React, { useState, useEffect } from 'react';
import Calendar from 'react-calendar';
import 'react-calendar/dist/Calendar.css';
import './RegisterHours.css';
import axios from 'axios';

function RegisterHours({ userId, selectedDate, setSelectedDate, token }) {
    const [offerteOptions, setOfferteOptions] = useState([]);
    const [searchOfferte, setSearchOfferte] = useState('');
    const [showOfferteDropdown, setShowOfferteDropdown] = useState(false);
    const [allRegisteredDates, setAllRegisteredDates] = useState([]);
    const [hours, setHours] = useState('');
    const [fetchedHours, setFetchedHours] = useState([]);
    const [plannedHours, setPlannedHours] = useState([]);
    const [isLoadingRegistered, setIsLoadingRegistered] = useState(false);
    const [isLoadingPlanned, setIsLoadingPlanned] = useState(false);
    const [notification, setNotification] = useState({ show: false, message: '', type: '' });
    const [displayedMonth, setDisplayedMonth] = useState(new Date());
    const [startTime, setStartTime] = useState('');
    const [endTime, setEndTime] = useState('');
    const [showCalendar, setShowCalendar] = useState(true);
    const [pauze, setPauze] = useState(0);
    const [offertenummer, setOffertenummer] = useState('');
    const [hoursPerDate, setHoursPerDate] = useState({});
    const [monthlyPlanningData, setMonthlyPlanningData] = useState([]);
    const [opmerkingen, setOpmerkingen] = useState('');
    const [werkzaamheden, setWerkzaamheden] = useState("");
    const [recentOfferteOptions, setRecentOfferteOptions] = useState([]);
    
    // Define exempt werkzaamheden codes that don't require offertenummer
    const exemptWerkzaamhedenCodes = ['71', '72', '73', '75', '76', '77', '78', '79', '80', '82', '84'];
    
    const loadRecentOfferteOptions = () => {
        try {
          const storedOptions = localStorage.getItem(`recentOfferteOptions_${userId}`);
          if (storedOptions) {
            setRecentOfferteOptions(JSON.parse(storedOptions));
          }
        } catch (error) {
          console.error("Error loading recent options:", error);
        }
      };

// Update how options are saved to ensure location is included
const saveRecentOfferteOption = (option) => {
    try {
      // Make sure we have the full option data with location
      const fullOption = {
        offertenummer: option.offertenummer,
        klant: option.klant || searchOfferte.split(' - ')[1] || '',
        locatie: option.locatie || searchOfferte.split(' - ')[2] || ''
      };
      
      // Create a new array with the current option at the front
      const updatedOptions = [fullOption];
      
      // Add previous options, avoiding duplicates, up to 5 items
      recentOfferteOptions.forEach(oldOption => {
        if (updatedOptions.length < 5 && oldOption.offertenummer !== fullOption.offertenummer) {
          updatedOptions.push(oldOption);
        }
      });
      
      // Save to state and localStorage
      setRecentOfferteOptions(updatedOptions);
      localStorage.setItem(`recentOfferteOptions_${userId}`, JSON.stringify(updatedOptions));
    } catch (error) {
      console.error("Error saving recent option:", error);
    }
  };

    // Function to check if current werkzaamheden is exempt from offertenummer requirement
    const isWerkzaamhedenExempt = () => {
        if (!werkzaamheden) return false;
        return exemptWerkzaamhedenCodes.some(code => werkzaamheden.startsWith(code));
    };

    const Notification = ({ message, type, onClose }) => (
        <div className={`notification ${type}`}>
            <span>{message}</span>
            <button onClick={onClose}>×</button>
        </div>
    ); 

    const showNotification = (message, type = 'success') => {
        setNotification({ show: true, message, type });
        setTimeout(() => {
            setNotification({ show: false, message: '', type: '' });
        }, 3000);
    };

    const werkzaamhedenOptions = [
        { value: "10-briefing", label: "10-briefing" },
        { value: "11-voorbereiding / laden", label: "11-voorbereiding / laden" },
        { value: "12-ontwerp", label: "12-ontwerp" },
        { value: "13-productie / aanmaak", label: "13-productie / aanmaak" },
        { value: "14-heenreis chauffeur", label: "14-heenreis chauffeur" },
        { value: "15-terugreis chauffeur", label: "15-terugreis chauffeur" },
        { value: "16-heenreis passagier", label: "16-heenreis passagier" },
        { value: "17-terugreis passagier", label: "17-terugreis passagier" },
        { value: "18-montage", label: "18-montage" },
        { value: "19-demontage", label: "19-demontage" },
        { value: "21-nazorg/loss/oprm", label: "21-nazorg/loss/oprm" },
        { value: "30-projectmanagement", label: "30-projectmanagement" },
        { value: "31-werkvoorbereiding", label: "31-werkvoorbereiding" },
        { value: "32-calculatie", label: "32-calculatie" },
        { value: "34-DTP", label: "34-DTP" },
        { value: "35-audiovisueel", label: "35-audiovisueel" },
        { value: "37-software/ontwikkeling", label: "37-software/ontwikkeling" },
        { value: "40-wachturen", label: "40-wachturen" },
        { value: "50-administratie", label: "50-administratie" },
        { value: "52-acquisitie", label: "52-acquisitie" },
        { value: "71-ziek", label: "71-ziek" },
        { value: "72-tandarts", label: "72-tandarts" },
        { value: "73-dokter", label: "73-dokter" },
        { value: "75-onbetaald verlof", label: "75-onbetaald verlof" },
        { value: "76-feestdag", label: "76-feestdag" },
        { value: "77-zorgverlof", label: "77-zorgverlof" },
        { value: "78-geboorteverlof", label: "78-geboorteverlof" },
        { value: "79-aanvullend geboorteverlof", label: "79-aanvullend geboorteverlof" },
        { value: "80-ouderschapsverlof", label: "80-ouderschapsverlof" },
        { value: "81-intern diversen", label: "81-intern diversen" },
        { value: "82-onbetaald ouderschapsverlof", label: "82-onbetaald ouderschapsverlof" },
        { value: "84-snipperuren", label: "84-snipperuren" },
        { value: "85-onderhoud elektra", label: "85-onderhoud elektra" },
        { value: "87-transport tbv klant", label: "87-transport tbv klant" },
    ];

    // Function to format time input
    const formatTimeInput = (input) => {
        if (!input) return '';
        
        // Remove any non-numeric characters
        let numericValue = input.replace(/\D/g, '');
        
        // Handle single digit (convert "1" to "01:00")
        if (numericValue.length === 1) {
            return `0${numericValue}:00`;
        }
        
        // Handle two digits (convert "15" to "15:00")
        if (numericValue.length === 2) {
            return `${numericValue}:00`;
        }
        
        // Handle three digits (convert "130" to "01:30")
        if (numericValue.length === 3) {
            return `0${numericValue[0]}:${numericValue.substring(1)}`;
        }
        
        // Handle four digits (convert "1530" to "15:30")
        if (numericValue.length >= 4) {
            const hours = numericValue.substring(0, 2);
            const minutes = numericValue.substring(2, 4);
            
            // Validate hours and minutes
            if (parseInt(hours) > 23) return '23:59';
            if (parseInt(minutes) > 59) return `${hours}:59`;
            
            return `${hours}:${minutes}`;
        }
        
        return input;
    };

    // Handle time input changes
    const handleTimeInputChange = (e, setterFunction) => {
        let value = e.target.value;
        
        // If the input contains ':', keep the raw input for now
        if (value.includes(':')) {
            setterFunction(value);
            return;
        }
        
        // Format the input when the user has finished typing
        const formattedTime = formatTimeInput(value);
        setterFunction(formattedTime);
    };

    // Handle time input blur (when user leaves the field)
    const handleTimeInputBlur = (e, setterFunction) => {
        const formattedTime = formatTimeInput(e.target.value);
        setterFunction(formattedTime);
    };

    const calculateWorkedHours = (start, end, breakMinutes) => {
        if (!start || !end) return; // Ensure both values are selected
    
        const [startHour, startMinute] = start.split(":").map(Number);
        const [endHour, endMinute] = end.split(":").map(Number);
        const startInMinutes = startHour * 60 + startMinute;
        const endInMinutes = endHour * 60 + endMinute;
    
        let breakTime = parseInt(breakMinutes) || 0;
        if (breakTime < 0) breakTime = 0;
    
        // Calculate in minutes first
        const totalMinutesWorked = Math.max(0, endInMinutes - startInMinutes - breakTime);
        
        // Convert to exact hours
        const hours = totalMinutesWorked / 60;
        
        console.log(`Worked Hours Calculation: Start=${start}, End=${end}, Pauze=${breakTime}, Result=${hours}`);
        setHours(String(hours)); // Store as string to preserve exact value
    };

    // Define preset breaks (in minutes)
    // const breaks = [
    //     { start: "09:30", end: "09:45", duration: 15 },
    //     { start: "12:00", end: "12:30", duration: 30 },
    //     { start: "14:45", end: "15:00", duration: 15 },
    // ];

    const fetchAllRegisteredHours = async () => {
        try {
            const response = await axios.get("https://fmhyper.com/filemaker-api.php", {
                params: {
                    action: "get-all-hours",
                    token,
                    userId
                }
            });
    
            if (response.data.success && Array.isArray(response.data.hours)) {
                const hoursPerDate = response.data.hours.reduce((acc, hour) => {
                    if (!acc[hour.date]) {
                        acc[hour.date] = 0;
                    }
                    acc[hour.date] += parseFloat(hour.hours);
                    return acc;
                }, {});
    
                setAllRegisteredDates(Object.keys(hoursPerDate));
                setHoursPerDate(hoursPerDate);
            }
        } catch (error) {
            console.error("Error fetching all registered hours:", error);
        }
    };

/**
 * Calculates automatic pause time based on work duration.
 * New pause schedule:
 * - After 2:00 hours of work: 15 minute pause
 * - After 4:30 hours total elapsed time (including previous pause): 30 minute pause
 * - After 7:15 hours total elapsed time (including previous pauses): 15 minute pause
 * 
 * This replaces the previous system of fixed-time pauses with a system based on
 * cumulative work duration.
 */
const calculateAutomaticPauze = (start, end) => {
    if (!start || !end) return 0; // No calculation if no times are selected

    const [startHour, startMinute] = start.split(":").map(Number);
    const [endHour, endMinute] = end.split(":").map(Number);

    const startInMinutes = startHour * 60 + startMinute;
    const endInMinutes = endHour * 60 + endMinute;

    // Calculate total elapsed time between start and end in minutes
    const totalElapsedMinutes = endInMinutes - startInMinutes;
    
    // Duration thresholds in minutes (total elapsed time including pauses)
    const firstThreshold = 120;  // 2:00 hours
    const secondThreshold = 270; // 4:30 hours (2:00 + 0:15 pause + 2:15)
    const thirdThreshold = 435;  // 7:15 hours (4:30 + 0:30 pause + 2:15)
    
    let totalPauseMinutes = 0;
    
    // Add pauses based on total elapsed time
    if (totalElapsedMinutes > firstThreshold) {
        totalPauseMinutes += 15; // First 15 min pause
        
        if (totalElapsedMinutes > secondThreshold) {
            totalPauseMinutes += 30; // Additional 30 min pause
            
            if (totalElapsedMinutes > thirdThreshold) {
                totalPauseMinutes += 15; // Additional 15 min pause
            }
        }
    }
    
    // Log the calculation for debugging
    console.log(`Pause calculation: Start=${start}, End=${end}, Total minutes=${totalElapsedMinutes}, Total pause=${totalPauseMinutes}`);
    
    return totalPauseMinutes;
};

    useEffect(() => {
        if (!selectedDate) {
            setSelectedDate(new Date());
        }
    }, [setSelectedDate, selectedDate]);

    useEffect(() => {
        const newPauze = calculateAutomaticPauze(startTime, endTime);
        setPauze(newPauze);
    }, [startTime, endTime]);

    // Ensure the function runs when values change
    useEffect(() => {
        calculateWorkedHours(startTime, endTime, pauze);
    }, [startTime, endTime, pauze]);

    const formatDateToLocal = (date) => {
        const year = date.getFullYear();
        const month = String(date.getMonth() + 1).padStart(2, '0');
        const day = String(date.getDate()).padStart(2, '0');
        return `${year}-${month}-${day}`;
    };

    useEffect(() => {
        if (userId && token) {
            fetchAllRegisteredHours();
        }
    }, [userId, token]);

    useEffect(() => {
        if (userId) {
          loadRecentOfferteOptions();
        }
      }, [userId]);

    useEffect(() => {
        const tableContainer = document.querySelector('.hours-table-container');
        if (tableContainer) {
            const checkScroll = () => {
                if (tableContainer.scrollWidth > tableContainer.clientWidth) {
                    tableContainer.classList.add('scrollable');
                } else {
                    tableContainer.classList.remove('scrollable');
                }
            };
            
            checkScroll();
            window.addEventListener('resize', checkScroll);
            return () => window.removeEventListener('resize', checkScroll);
        }
    }, []);

    useEffect(() => {
        fetchOfferteOptions();
    }, []);

    useEffect(() => {
        const handleClickOutside = (event) => {
            if (!event.target.closest('.offerte-dropdown-container')) {
                setShowOfferteDropdown(false);
            }
        };
    
        document.addEventListener('click', handleClickOutside);
        return () => document.removeEventListener('click', handleClickOutside);
    }, []);

    // Reset offertenummer when werkzaamheden changes to an exempt code
    useEffect(() => {
        if (isWerkzaamhedenExempt()) {
            setOffertenummer('');
            setSearchOfferte('');
        }
    }, [werkzaamheden]);

    const fetchRegisteredHours = async (date) => {
        if (!date) return;
        setIsLoadingRegistered(true);
        const formattedDate = formatDateToLocal(date);

        try {
            const response = await axios.get("https://fmhyper.com/filemaker-api.php?action=get-registered-hours", {
                params: { token, userId, date: formattedDate },
                headers: { "Content-Type": "application/json" }
            });

            if (response.data.success && response.data.hours.length > 0) {
                setFetchedHours(response.data.hours);
            } else {
                setFetchedHours([]);
            }
        } catch (error) {
            console.error("Error fetching registered hours:", error);
            setFetchedHours([]);
        } finally {
            setIsLoadingRegistered(false);
        }
    };

// Around line 233
// Updated formatDecimalToHHMM function
const formatDecimalToHHMM = (decimalHours) => {
    // Return empty string instead of "00:00" if no hours are specified
    if (!decimalHours && decimalHours !== 0) return '';
    
    // Convert string to number if needed
    const hours = typeof decimalHours === 'string' ? parseFloat(decimalHours) : decimalHours;
    
    // Return empty string if hours is NaN
    if (isNaN(hours)) return '';
    
    // Calculate total minutes without rounding
    const totalMinutes = Math.floor(hours * 60);
    
    // Extract hours and minutes
    const hh = Math.floor(totalMinutes / 60);
    const mm = totalMinutes % 60;
    
    return `${String(hh).padStart(2, '0')}:${String(mm).padStart(2, '0')}`;
};
const validateEndTime = (start, end) => {
    if (!start || !end) return true; // Skip validation if either is empty
    
    const [startHour, startMinute] = start.split(":").map(Number);
    const [endHour, endMinute] = end.split(":").map(Number);
    
    const startInMinutes = startHour * 60 + startMinute;
    const endInMinutes = endHour * 60 + endMinute;
    
    return endInMinutes >= startInMinutes;
};

    const fetchOfferteOptions = async () => {
        try {
            // First get a token for CI_Project
            const tokenResponse = await axios.get("https://fmhyper.com/filemaker-api.php?action=get-project-token");
            
            if (!tokenResponse.data.success) {
                console.error("Failed to get project token:", tokenResponse.data.message);
                return;
            }
        
            const projectToken = tokenResponse.data.token;
        
            // Now fetch the options
            const response = await axios.get("https://fmhyper.com/filemaker-api.php", {
                params: {
                    action: "get-offertenummer-options",
                    token: projectToken
                }
            });
        
            if (response.data.success && Array.isArray(response.data.options)) {
                setOfferteOptions(response.data.options);
            }
        } catch (error) {
            console.error("Error fetching offerte options:", error);
        }
    };

    const fetchPlannedHoursForDay = async (date) => {
        if (!date) return;
        setIsLoadingPlanned(true);
        const formattedDate = formatDateToLocal(date);

        try {
            const tokenResponse = await axios.get("https://fmhyper.com/filemaker-api.php?action=get-plb-token");
            if (!tokenResponse.data.success) {
                console.error("Failed to get new PLB token:", tokenResponse.data.message);
                return;
            }

            const newPlbToken = tokenResponse.data.token;
            const response = await axios.get("https://fmhyper.com/filemaker-api.php", {
                params: {
                    action: "get-planned-hours",
                    token: newPlbToken,
                    userId,
                    date: formattedDate
                }
            });

            if (response.data.success && response.data.plannedHours.length > 0) {
                setPlannedHours(response.data.plannedHours);
            } else {
                setPlannedHours([]);
            }
        } catch (error) {
            console.error("Error fetching planned hours:", error);
            setPlannedHours([]);
        } finally {
            setIsLoadingPlanned(false);
        }
    };

    useEffect(() => {
        if (selectedDate) {  // ✅ Only call when `selectedDate` exists
            fetchRegisteredHours(selectedDate);
            fetchPlannedHoursForDay(selectedDate);
        }
    }, [selectedDate, userId]);

    const submitHours = async (e) => {
        e.preventDefault();

        if (!selectedDate) {
            alert("Please select a date before submitting.");
            return;
        }

        const formattedDate = formatDateToLocal(selectedDate);

        const requestData = {
            action: "submit-hours",
            token,
            userId,
            date: formattedDate,
            startTime,
            endTime,
            hours: parseFloat(hours),
            pauze: parseInt(pauze),
            offertenummer: isWerkzaamhedenExempt() ? '' : offertenummer,
            werkzaamheden,
            opmerkingen
        };

        console.log("Submitting Hours Request Data:", requestData);

        try {
            const response = await axios.post("https://fmhyper.com/filemaker-api.php", requestData);

            if (response.data && response.data.success) {
                // First update the data
                if (offertenummer && !isWerkzaamhedenExempt()) {
                    const selectedOption = offerteOptions.find(opt => opt.offertenummer === offertenummer) || 
                                          { offertenummer, klant: searchOfferte.replace(`${offertenummer} - `, '') };
                    saveRecentOfferteOption(selectedOption);
                  }

                await Promise.all([
                    fetchAllRegisteredHours(),
                    fetchRegisteredHours(selectedDate)
                ]);
                
                // Clear all form fields
                setStartTime('');
                setEndTime('');
                setPauze(0);
                setHours('');
                setOffertenummer('');
                setSearchOfferte('');
                setWerkzaamheden('');
                setOpmerkingen('');
                
                // Scroll to top
                window.scrollTo({ top: 0, behavior: 'smooth' });
                
                // Then show notification once all updates are complete
                showNotification('Uren zijn succesvol geregistreerd');
            } else {
                showNotification(response.data?.message || "Er is een fout opgetreden", 'error');
            }
        } catch (error) {
            showNotification(error.response?.data?.message || "Er is een fout opgetreden", 'error');
        }
    };

    const calculateHourlySummary = (entries = []) => {
        if (!Array.isArray(entries) || entries.length === 0) return {
            "100%": 0,
            "125%": 0,
            "150%": 0,
            "200%": 0,
            "Reistijd 0%": 0,
            "Reistijd 25%": 0,
            "Reistijd 50%": 0,
            "Reistijd 100%": 0,
            "Reistijd 150%": 0
        };
    
        let summary = {
            "100%": 0,
            "125%": 0,
            "150%": 0,
            "200%": 0,
            "Reistijd 0%": 0,
            "Reistijd 25%": 0,
            "Reistijd 50%": 0,
            "Reistijd 100%": 0,
            "Reistijd 150%": 0
        };
    
        // Group entries by date
        let dailyWorkHours = {};
        entries.forEach((entry) => {
            if (!entry || typeof entry !== "object") return;
    
            let { date, startTime, endTime, hours, werkzaamheden } = entry;
            let workedHours = parseFloat(hours) || 0;
            if (!date) return;
    
            // Create a proper date object
            const entryDate = new Date(date);
            const dayOfWeek = entryDate.getDay(); // 0=Sunday, 6=Saturday
            
            if (!dailyWorkHours[date]) dailyWorkHours[date] = [];
            dailyWorkHours[date].push({
                startTime,
                endTime,
                workedHours,
                werkzaamheden,
                dayOfWeek
            });
        });
    
        // Process each day's entries
        Object.keys(dailyWorkHours).forEach((date) => {
            const dailyEntries = dailyWorkHours[date];
            const totalWorkedHours = dailyEntries.reduce((sum, entry) => sum + entry.workedHours, 0);
            
            // Get the day of week from the first entry
            const dayOfWeek = dailyEntries[0].dayOfWeek;
            const isSunday = dayOfWeek === 0;
            const isFriday = dayOfWeek === 5;
            const isSaturday = dayOfWeek === 6;
            const isWeekday = !isSunday && !isSaturday;
            
            // TODO: Add holiday detection if needed
            const isHoliday = false; // Placeholder for holiday detection
    
            // REGULAR WORK HOURS (not travel hours)
            if (isHoliday || isSaturday) {
                // All hours on holidays are 150%
                summary["150%"] += totalWorkedHours;
                
            } else if (isSunday) {
                // All hours on Sunday are 100% (which is actually 200% of base rate)
                summary["200%"] += totalWorkedHours;
            } else if (isFriday) {
                // FRIDAY SPECIFIC LOGIC: 
                // First 6 hours at 100%, then up to 8 hours at 125%, anything over 8 at 150%
                if (totalWorkedHours <= 6) {
                    // First 6 hours at 100%
                    summary["100%"] += totalWorkedHours;
                } else if (totalWorkedHours <= 8) {
                    // First 6 hours at 100%
                    summary["100%"] += 6;
                    // Hours 6-8 at 125%
                    summary["125%"] += (totalWorkedHours - 6);
                } else {
                    // First 6 hours at 100%
                    summary["100%"] += 6;
                    // Next 2 hours at 125%
                    summary["125%"] += 2;
                    // Remaining hours at 150%
                    summary["150%"] += (totalWorkedHours - 8);
                }
            } else {
                // Regular weekday calculation (Monday-Thursday)
                if (totalWorkedHours <= 8) {
                    // First 8 hours are always 100%
                    summary["100%"] += totalWorkedHours;
                } else {
                    // First 8 hours at 100%
                    summary["100%"] += 8;
                    
                    const overtimeHours = totalWorkedHours - 8;
                    
                    if (overtimeHours <= 3) {
                        // Next 3 hours at 125%
                        summary["125%"] += overtimeHours;
                    } else {
                        // First 3 overtime hours at 125%
                        summary["125%"] += 3;
                        // Additional overtime hours at 150%
                        summary["150%"] += (overtimeHours - 3);
                    }
                }
            }
    
            // TRAVEL HOURS FOR PASSENGERS section remains unchanged
            dailyEntries.forEach(entry => {
                if (!entry.werkzaamheden || !entry.werkzaamheden.includes("passagier")) return;
                
                const { workedHours } = entry;
                
                if (isHoliday) {
                    // First 1.5 hours at 0%, rest at 150%
                    const baseHours = Math.min(1.5, workedHours);
                    summary["Reistijd 0%"] += baseHours;
                    if (workedHours > 1.5) {
                        summary["Reistijd 150%"] += (workedHours - 1.5);
                    }
                } else if (isSunday) {
                    // First 1.5 hours at 0%, rest at 100% (which is 200% of base)
                    const baseHours = Math.min(1.5, workedHours);
                    summary["Reistijd 0%"] += baseHours;
                    if (workedHours > 1.5) {
                        summary["Reistijd 100%"] += (workedHours - 1.5);
                    }
                } else if (isSaturday) {
                    // First 1.5 hours at 0%, rest at 50%
                    const baseHours = Math.min(1.5, workedHours);
                    summary["Reistijd 0%"] += baseHours;
                    if (workedHours > 1.5) {
                        summary["Reistijd 50%"] += (workedHours - 1.5);
                    }
                } else {
                    // Weekday travel
                    if (workedHours <= 1.5) {
                        // All hours up to 1.5 at 0%
                        summary["Reistijd 0%"] += workedHours;
                    } else if (workedHours <= 3) {
                        // First 1.5 hours at 0%
                        summary["Reistijd 0%"] += 1.5;
                        // Next 1.5 hours at 25%
                        summary["Reistijd 25%"] += (workedHours - 1.5);
                    } else {
                        // First 1.5 hours at 0%
                        summary["Reistijd 0%"] += 1.5;
                        // Next 1.5 hours at 25%
                        summary["Reistijd 25%"] += 1.5;
                        // Rest at 50%
                        summary["Reistijd 50%"] += (workedHours - 3);
                    }
                }
            });
        });
    
        // Return all categories, even if they're 0
        return summary;
    };

    useEffect(() => {
        const fetchPlanningData = async () => {
            if (!selectedDate) return;
        
const firstDay = new Date(selectedDate.getFullYear(), selectedDate.getMonth(), 1);
firstDay.setDate(firstDay.getDate() - 10); 

const lastDay = new Date(selectedDate.getFullYear(), selectedDate.getMonth() + 1, 0);
lastDay.setDate(lastDay.getDate() + 10); 
        
            try {
                const response = await axios.get("https://fmhyper.com/filemaker-api.php", {
                    params: {
                        action: "get-planning",
                        token,
                        userId,
                        startDate: formatDateToLocal(firstDay),
                        endDate: formatDateToLocal(lastDay)
                    }
                });
        
                if (response.data.success) {
                    console.log("Monthly Planning Data:", response.data.events);
                    setMonthlyPlanningData(response.data.events);
                }
            } catch (error) {
                console.error("Error fetching planning data:", error);
            }
        };
    
        fetchPlanningData();
    }, [selectedDate, userId, token]);

    const deleteRegisteredHour = async (entry) => {
        if (!window.confirm(`Weet je zeker dat je de uren van ${entry.date} wilt verwijderen?`)) {
            return;
        }
        console.log(entry)
        try {
            const response = await axios.post("https://fmhyper.com/filemaker-api.php?action=delete-registered-hour", {
                token,
                userId,
                id: entry.id
            });

            if (response.data.success) {
                showNotification('Uren zijn succesvol verwijderd');
                setFetchedHours(fetchedHours.filter((item) => item !== entry));
            } else {
                showNotification(response.data.message || "Er is een fout opgetreden", 'error');
            }
        } catch (error) {
            showNotification(error.response?.data?.message || "Er is een fout opgetreden", 'error');
        }
    };

    return (
        <div className="register-container">
            {notification.show && (
                <Notification 
                    message={notification.message} 
                    type={notification.type} 
                    onClose={() => setNotification({ show: false, message: '', type: '' })}
                />
            )}
            <h2>Uren Registratie</h2>
            <button onClick={() => setShowCalendar(!showCalendar)} className="toggle-calendar-button">
                {showCalendar ? '▼' : '▲'} Kalender
            </button>

            {showCalendar && (
                <div className="calendar-container">
                    <Calendar
                        onChange={setSelectedDate}
                        value={selectedDate}
                        onActiveStartDateChange={({ action, view, activeStartDate }) => {
                            if (action === 'prev' || action === 'next' || action === 'drillUp') {
                                const firstOfMonth = new Date(activeStartDate.getFullYear(), activeStartDate.getMonth(), 1);
                                setSelectedDate(firstOfMonth);
                            }
                        }}
                        tileClassName={({ date, view }) => {
                            if (view !== 'month') return null;
                            const dateString = formatDateToLocal(date);
                            const isHighlighted = allRegisteredDates.includes(dateString);
                            const isSelected = selectedDate && date.toDateString() === selectedDate.toDateString();
                            const totalHours = hoursPerDate[dateString] || 0;
                            const isFriday = date.getDay() === 5;
                            const isComplete = isFriday ? totalHours >= 5 : totalHours >= 8;
                            
                            // Create date-only comparison objects (without time)
                            const currentDateWithoutTime = new Date(date.getFullYear(), date.getMonth(), date.getDate());
                            
                            const hasPlannedHours = monthlyPlanningData
                                .filter(event => event.WORid) // Only consider events with a valid WORid
                                .some(event => {
                                    // Convert to date objects without time component
                                    const startDate = new Date(event.start);
                                    const startDateWithoutTime = new Date(startDate.getFullYear(), startDate.getMonth(), startDate.getDate());
                                    
                                    const endDate = new Date(event.end);
                                    const endDateWithoutTime = new Date(endDate.getFullYear(), endDate.getMonth(), endDate.getDate());
                                    
                                    return (
                                        currentDateWithoutTime >= startDateWithoutTime && 
                                        currentDateWithoutTime <= endDateWithoutTime
                                    );
                                });
                        
                            if (isSelected && (isHighlighted || hasPlannedHours)) {
                                return isComplete ? "highlighted-date selected-date" : "highlighted-date selected-date incomplete";
                            }
                            if (isSelected) {
                                return "selected-date";
                            }
                            if (isHighlighted) {
                                return isComplete ? "highlighted-date" : "highlighted-date incomplete";
                            }
                            if (hasPlannedHours) {
                                return "planned-date";
                            }
                            return null;
                        }}
                        locale="nl-NL"
                        formatMonthYear={(locale, date) => 
                            date.toLocaleDateString("nl-NL", { month: 'long', year: 'numeric' })
                        }
                        formatShortWeekday={(locale, date) => 
                            date.toLocaleDateString("nl-NL", { weekday: 'short' }).slice(0, 2)
                        }
                    />
                </div>
            )}

            <h3 className="selected-date-header">
                {selectedDate?.toLocaleDateString("nl-NL", {
                    day: "2-digit",
                    month: "2-digit",
                    year: "numeric",
                }) || "Geen datum geselecteerd"}
            </h3>

            <br></br>
            
            <form onSubmit={submitHours} className="register-form">
                <div className="input-group">
                    <label htmlFor="startTime">Starttijd</label>
                    <input 
    type="text"
    id="startTime"
    inputMode="numeric"
    pattern="[0-9:]*"
    placeholder="bijv. 0900 of 09:00"
    value={startTime}
    onChange={(e) => setStartTime(e.target.value)}
    onBlur={(e) => {
        handleTimeInputBlur(e, setStartTime);
        // If end time exists and is now before start time, reset it
        if (endTime && !validateEndTime(formatTimeInput(e.target.value), endTime)) {
            setEndTime('');
        }
    }}
    required
/>
                </div>

                <div className="input-group">
                    <label htmlFor="endTime">Eindtijd</label>
                    <input 
    type="text"
    id="endTime"
    inputMode="numeric"
    pattern="[0-9:]*"
    placeholder="bijv. 1700 of 17:00"
    value={endTime}
    onChange={(e) => setEndTime(e.target.value)}
    onBlur={(e) => {
        const formattedEnd = formatTimeInput(e.target.value);
        // Only set the end time if it's valid (after start time)
        if (!startTime || validateEndTime(startTime, formattedEnd)) {
            handleTimeInputBlur(e, setEndTime);
        } else {
            // Reset to empty
            setEndTime('');
            showNotification('Eindtijd moet later zijn dan starttijd', 'error');
        }
    }}
    required
/>
                </div>

                <div className="input-group">
                    <label htmlFor="pauze">Pauze (minuten)</label>
                    <input 
                        type="number" 
                        id="pauze" 
                        value={pauze} 
                        onChange={(e) => setPauze(parseInt(e.target.value) || 0)} 
                        readOnly 
                        required 
                    />
                </div>

                <div className="input-group">
                    <label htmlFor="hours">Gewerkte uren</label>
                    <input 
                        type="text" 
                        id="hours" 
                        value={formatDecimalToHHMM(hours)} 
                        readOnly 
                        required 
                    />
                </div>

                <div className="input-group">
                    <label htmlFor="werkzaamheden">Werkzaamheden</label>
                    <select 
                        id="werkzaamheden" 
                        value={werkzaamheden} 
                        onChange={(e) => setWerkzaamheden(e.target.value)} 
                        required
                    >
                        <option value="">Selecteer werkzaamheden</option>
                        {werkzaamhedenOptions.map((option) => (
                            <option key={option.value} value={option.value}>{option.label}</option>
                        ))}
                    </select>
                </div>

                <div className="input-group">
                    <label htmlFor="offertenummer">Offertenummer</label>
                    <div className="offerte-dropdown-container">
                        <input
                            type="text"
                            id="offertenummer"
                            value={searchOfferte}
                            onChange={(e) => {
                                setSearchOfferte(e.target.value);
                                setShowOfferteDropdown(true);
                            }}
                            onFocus={() => setShowOfferteDropdown(true)}
                            placeholder="Zoek offertenummer..."
                            required={!isWerkzaamhedenExempt()}
                            disabled={isWerkzaamhedenExempt()}
                            className={isWerkzaamhedenExempt() ? "input-disabled" : ""}
                        />
{showOfferteDropdown && !isWerkzaamhedenExempt() && (
  <div className="offerte-dropdown">
    {/* Recent options section */}
    {recentOfferteOptions.length > 0 && (
  <div className="recent-options-section">
    <div className="recent-options-header">Recent gebruikt</div>
    {recentOfferteOptions.map((option, index) => (
      <div
        key={`recent-${index}`}
        className="offerte-option recent-option"
        onClick={() => {
          setOffertenummer(option.offertenummer);
          // Format the display text to include location if it exists
          const displayText = option.locatie 
            ? `${option.offertenummer} - ${option.klant} - ${option.locatie}`
            : `${option.offertenummer} - ${option.klant}`;
          setSearchOfferte(displayText);
          setShowOfferteDropdown(false);
        }}
      >
        <span className="offerte-number">{option.offertenummer}</span>
        <span className="offerte-klant">
          {option.klant}
          {option.locatie && ` - ${option.locatie}`}
        </span>
        <span className="recent-icon">🕒</span>
      </div>
    ))}
    <div className="separator"></div>
  </div>
)}
    
    {/* Regular options - filtering as before */}
{[
    { offertenummer: '9999999', klant: 'intern diverse', locatie: '' },
  ...offerteOptions
 
]
  .filter(option => 
    option.offertenummer.toLowerCase().includes(searchOfferte.toLowerCase()) ||
    option.klant.toLowerCase().includes(searchOfferte.toLowerCase()) ||
    (option.locatie && option.locatie.toLowerCase().includes(searchOfferte.toLowerCase()))
  )
  .slice(0, 10) // Limit to 10 results
  .map((option, index) => (
    <div
      key={index}
      className="offerte-option"
      onClick={() => {
        setOffertenummer(option.offertenummer);
        // Format the display text to include location if it exists
        const displayText = option.locatie 
          ? `${option.offertenummer} - ${option.klant} - ${option.locatie}`
          : `${option.offertenummer} - ${option.klant}`;
        setSearchOfferte(displayText);
        setShowOfferteDropdown(false);
      }}
    >
      <span className="offerte-number">{option.offertenummer}</span>
      <span className="offerte-klant">
        {option.klant}
        {option.locatie && ` - ${option.locatie}`}
      </span>
    </div>
  ))}
  </div>
)}
                    </div>
                </div>

                <div className="input-group">
                    <label htmlFor="opmerkingen">Opmerkingen</label>
                    <input
                        type="text"
                        id="opmerkingen"
                        value={opmerkingen}
                        onChange={(e) => setOpmerkingen(e.target.value)}
                        className="opmerkingen-input"
                    />
                </div>

                <button type="submit" className="register-button">Registreer Uren</button>
            </form>

            {isLoadingPlanned ? (
                <div className="planned-hours-container">
                    <h3>Geplande uren</h3>
                    <br />
                    <div className="loading-spinner" />
                </div>
            ) : (
                <div className="planned-hours-container">
                    <h3>Geplande uren</h3>
                    <br />
                    {plannedHours.length > 0 ? (
                        <table className="hours-table">
                            <thead>
                                <tr>
                                    <th>Starttijd</th>
                                    <th>Eindtijd</th>
                                    <th>Omschrijving</th>
                                </tr>
                            </thead>
                            <tbody>
                                {plannedHours.map((event, index) => (
                                    <tr key={index}>
                                        <td>{event.start}</td>
                                        <td>{event.end}</td>
                                        <td>{event.title || 'Geen beschrijving'}</td>
                                    </tr>
                                ))}
                            </tbody>
                        </table>
                    ) : (
                        <div className="no-data-message">Geen geplande uren</div>
                    )}
                </div>
            )}

            {fetchedHours.length > 0 && (
                <div className="registered-hours-container">
                    <h3>
                        Uren: {" "}
                        {selectedDate.toLocaleDateString("nl-NL", {
                            day: "2-digit",
                            month: "2-digit",
                            year: "numeric",
                        })}
                    </h3>

                    <div className="hours-table-container">
                        <table className="hours-table">
                            <thead>
                                <tr>
                                    <th>Datum</th>
                                    <th>Starttijd</th>
                                    <th>Eindtijd</th>
                                    <th>Uren</th>
                                    <th>Pauze</th>
                                    <th>Acties</th>
                                </tr>
                            </thead>
                            <tbody>
                                {fetchedHours.map((entry, index) => (
                                    <React.Fragment key={index}>
                                        {/* First Row: Main Hour Details */}
                                        <tr>
                                            <td rowSpan="2">
                                                {new Date(entry.date).toLocaleDateString("nl-NL", {
                                                    day: "2-digit",
                                                    month: "2-digit",
                                                    year: "numeric",
                                                })}
                                            </td>
                                            <td>{entry.startTime || "-"}</td>
                                            <td>{entry.endTime || "-"}</td>
                                            <td>{formatDecimalToHHMM(parseFloat(entry.hours))}</td>
                                            <td>{entry.pauze || "0"} min</td>
                                            <td rowSpan="2">
                                                <button
                                                    className="delete-button"
                                                    onClick={() => deleteRegisteredHour(entry)}
                                                >
                                                    ❌
                                                </button>
                                            </td>
                                        </tr>
                                        {/* Second Row: Project Number */}
                                        <tr>
                                            <td colSpan="2">
                                                <strong>Offerte:</strong> {entry.offertenummer || "-"}
                                            </td>
                                            <td colSpan="2">
                                                <strong>Type:</strong> {entry.werkzaamheden || "N.v.t."}
                                            </td>
                                        </tr>
                                    </React.Fragment>
                                ))}
                            </tbody>
                        </table>
                    </div>

                    {/* Summary Table */}
                    <div className="summary-container">
                        <h3>Samenvatting</h3>
                        <br></br>
                        <table className="hours-table">
                            <thead>
                                <tr>
                                    <th>Soort Uur</th>
                                    <th>Gewerkte Uren</th>
                                </tr>
                            </thead>
                            <tbody>
                                {Object.entries(calculateHourlySummary(fetchedHours)).map(([type, hours], index) => (
                                    <tr key={index}>
                                        <td>{type}</td>
                                        <td>{formatDecimalToHHMM(hours)}</td>
                                    </tr>
                                ))}
                            </tbody>
                        </table>
                    </div>
                </div>
            )}
        </div>
    );
}

export default RegisterHours;