import React, { useState, useEffect, useCallback } from "react";
import { HashRouter as Router, Route, Routes, Navigate, Link, useLocation, useNavigate } from "react-router-dom";
import { LayoutDashboard, Clock, Calendar, Umbrella, LogOut } from 'lucide-react';
import Login from "./Login";
import Dashboard from "./Dashboard";
import RegisterHours from "./RegisterHours";
import Planning from "./Planning";
import Vacation from "./Vacation"; // Import the new Vacation component
import ProtectedRoute from "./ProtectedRoute";
import "./App.css";

function App() {
  const [loggedIn, setLoggedIn] = useState(false);
  const [token, setToken] = useState("");
  const [username, setUsername] = useState("");
  const [menuOpen, setMenuOpen] = useState(false);
  const [selectedDate, setSelectedDate] = useState(null);
  const [tokenError, setTokenError] = useState(false);

  // Improved authentication check on component mount
  useEffect(() => {
    const storedToken = localStorage.getItem('token');
    const storedUsername = localStorage.getItem('username');
    
    if (storedToken && storedUsername) {
      setLoggedIn(true);
      setToken(storedToken);
      setUsername(storedUsername);
    } else {
      handleLogout();
    }
  }, []);

  // Auto-close menu when logged out
  useEffect(() => {
    if (!loggedIn) {
      setMenuOpen(false);
    }
  }, [loggedIn]);

  const toggleMenu = () => {
    setMenuOpen(!menuOpen);
  };

  const handleLogout = useCallback(() => {
    console.log('Logout handler called');
    setLoggedIn(false);
    setToken('');
    setUsername('');
    setTokenError(false);
    localStorage.removeItem('token');
    localStorage.removeItem('username');
    localStorage.removeItem('plbToken');
    localStorage.removeItem('registeredHours');
  }, []);

  const handleLogin = useCallback((tokenValue, usernameValue) => {
    console.log('Login handler called', tokenValue, usernameValue);
    setLoggedIn(true);
    setToken(tokenValue);
    setUsername(usernameValue);
    setTokenError(false);
  }, []);

  // Function to handle token-related errors
  const handleTokenError = useCallback(() => {
    console.log('Token error detected');
    setTokenError(true);
    handleLogout();
  }, [handleLogout]);

  return (
    <Router>
      <div className="app-container">
        {loggedIn && !tokenError && (
          <>
            <Sidebar 
              menuOpen={menuOpen} 
              toggleMenu={toggleMenu} 
              onLogout={handleLogout} 
            />
            <div 
              className={`menu-icon ${menuOpen ? "rotating" : ""}`} 
              onClick={toggleMenu}
            >
              ☰
            </div>
          </>
        )}

        <div className={`content ${menuOpen ? "shift" : ""}`}>
          <Routes>
            <Route
              path="/"
              element={
                loggedIn && !tokenError ? (
                  <Navigate to="/dashboard" replace />
                ) : (
                  <Login
                    setLoggedIn={(isLoggedIn) => {
                      // Wrap the setLoggedIn to ensure it works with both boolean and token
                      if (typeof isLoggedIn === 'boolean') {
                        setLoggedIn(isLoggedIn);
                      }
                    }}
                    setToken={setToken}
                    setUserId={setUsername}
                    setPlbToken={(plbToken) => {
                      // Optional: handle PLB token if needed
                      localStorage.setItem('plbToken', plbToken);
                    }}
                  />
                )
              }
            />
            
            <Route
              path="/dashboard"
              element={
                <ProtectedRoute loggedIn={loggedIn && !tokenError}>
                  <Dashboard 
                    username={username} 
                    token={token} 
                    setToken={setToken} 
                    onTokenError={handleTokenError}
                  />
                </ProtectedRoute>
              }
            />
            
            <Route
              path="/uren-registratie"
              element={
                <ProtectedRoute loggedIn={loggedIn && !tokenError}>
                  <RegisterHours 
                    userId={username} 
                    token={token} 
                    setToken={setToken} 
                    selectedDate={selectedDate} 
                    setSelectedDate={setSelectedDate}
                    onTokenError={handleTokenError}
                  />
                </ProtectedRoute>
              }
            />
            
            <Route
              path="/planning"
              element={
                <ProtectedRoute loggedIn={loggedIn && !tokenError}>
                  <Planning 
                    userId={username} 
                    token={token} 
                    setToken={setToken} 
                    onTokenError={handleTokenError}
                  />
                </ProtectedRoute>
              }
            />
            
            {/* Add the new Vacation route */}
            <Route
              path="/vakantie"
              element={
                <ProtectedRoute loggedIn={loggedIn && !tokenError}>
                  <Vacation
                    userId={username}
                    token={token}
                    setToken={setToken}
                    onTokenError={handleTokenError}
                  />
                </ProtectedRoute>
              }
            />
            
            <Route 
              path="*" 
              element={
                loggedIn && !tokenError ? 
                  <Navigate to="/dashboard" replace /> : 
                  <Navigate to="/" replace />
              } 
            />
          </Routes>
        </div>
      </div>
    </Router>
  );
}

// Sidebar Component
function Sidebar({ menuOpen, toggleMenu, onLogout }) {
  const location = useLocation();
  const navigate = useNavigate();

  // Save current path to localStorage when a menu item is clicked
  useEffect(() => {
    if (location.pathname !== '/') {
      localStorage.setItem('lastPath', location.pathname);
    }
  }, [location.pathname]);

  const handleLogout = () => {
    console.log('Sidebar logout called');
    onLogout();
    navigate('/');
  };

  const menuItems = [
    {
      path: "/dashboard",
      label: "Dashboard",
      icon: <LayoutDashboard size={20} />,
    },
    {
      path: "/uren-registratie",
      label: "Uren Registratie",
      icon: <Clock size={20} />,
    },
    {
      path: "/planning",
      label: "Planning",
      icon: <Calendar size={20} />,
    },
    {
      path: "/vakantie",
      label: "Vakantie",
      icon: <Umbrella size={20} />,
    },
  ];

  return (
    <nav className={`sidebar ${menuOpen ? "open" : ""}`}>
      <div className="sidebar-header">
        <img 
          // src="https://portal.cialona.nl/images/login-logo.png" 
          src="https://cialona.nl/wp-content/uploads/2025/01/Logo_EN_Horizontal_DIAP.svg"
          alt="Cialona Logo" 
          className="sidebar-logo" 
        />
      </div>
      <ul className="sidebar-menu">
        {menuItems.map((item) => (
          <li key={item.path}>
            <Link
              to={item.path}
              className={`sidebar-link ${location.pathname === item.path ? "active" : ""}`}
              onClick={toggleMenu}
            >
              <span className="sidebar-icon">{item.icon}</span>
              <span className="sidebar-label">{item.label}</span>
            </Link>
          </li>
        ))}
      </ul>
      <button className="logout-button" onClick={handleLogout}>
        <LogOut size={20} />
        <span>Uitloggen</span>
      </button>
    </nav>
  );
}

export default App;