import React, { useState, useEffect, useRef } from 'react';
import axios from 'axios';
import './Planning.css';

function Planning({ userId, token }) {
  const [events, setEvents] = useState([]);
  const [currentDate, setCurrentDate] = useState(new Date());
  const [isLoading, setIsLoading] = useState(true);
  const [selectedEvent, setSelectedEvent] = useState(null);
  const [showModal, setShowModal] = useState(false);
  const eventsContainerRef = useRef(null);
  const upcomingEventRef = useRef(null);
  const [initialLoad, setInitialLoad] = useState(true);
  const [actualNextEventId, setActualNextEventId] = useState(null);

  const fetchEvents = async (date) => {
    setIsLoading(true);
    try {
      const firstDay = new Date(date.getFullYear(), date.getMonth(), 1);
      const lastDay = new Date(date.getFullYear(), date.getMonth() + 1, 0);

      const response = await axios.get("https://fmhyper.com/filemaker-api.php", {
        params: {
          action: "get-planning",
          token,
          userId,
          startDate: formatDate(firstDay),
          endDate: formatDate(lastDay)
        }
      });

      if (response.data.success) {
        const groupedEvents = groupEvents(response.data.events);
        setEvents(groupedEvents);
        
        // Check if this is the first load
        if (initialLoad) {
          findGlobalNextEvent(groupedEvents);
          setInitialLoad(false);
        }
      }
    } catch (error) {
      console.error("Error fetching planning:", error);
    }
    setIsLoading(false);
  };

  const groupEvents = (events) => {
    const sortedEvents = [...events].sort((a, b) => new Date(a.start) - new Date(b.start));
    const grouped = [];
    let currentGroup = null;
    
    sortedEvents.forEach(event => {
      const eventTime = new Date(event.start);
      
      if (!currentGroup) {
        currentGroup = {
          mainEvent: event,
          subEvents: [],
          startDate: new Date(event.start),
          endDate: new Date(event.end)
        };
        grouped.push(currentGroup);
      } else {
        const timeDiff = (eventTime - new Date(currentGroup.mainEvent.start)) / 1000 / 60;
        
        if (timeDiff <= 2 && timeDiff > 0) {
          currentGroup.subEvents.push(event);
          if (new Date(event.end) > currentGroup.endDate) {
            currentGroup.endDate = new Date(event.end);
          }
        } else {
          currentGroup = {
            mainEvent: event,
            subEvents: [],
            startDate: new Date(event.start),
            endDate: new Date(event.end)
          };
          grouped.push(currentGroup);
        }
      }
    });
    
    return grouped;
  };

  // Find the actual next upcoming event globally
  const findGlobalNextEvent = (eventsList) => {
    if (!eventsList || eventsList.length === 0) return;
    
    const today = new Date();
    today.setHours(0, 0, 0, 0);
    
    const isCurrentMonth = 
      today.getMonth() === currentDate.getMonth() && 
      today.getFullYear() === currentDate.getFullYear();
    
    // Only look for the next event in the current month
    if (isCurrentMonth) {
      for (let i = 0; i < eventsList.length; i++) {
        const eventStart = new Date(eventsList[i].startDate);
        eventStart.setHours(0, 0, 0, 0);
        
        if (eventStart >= today) {
          // We found the next upcoming event
          console.log("Found next event:", eventsList[i].mainEvent.title, eventsList[i].mainEvent.id);
          setActualNextEventId(eventsList[i].mainEvent.id);
          return;
        }
      }
      
      // If no upcoming events in this month and it's initial load, 
      // move to next month automatically
      if (initialLoad) {
        setTimeout(() => {
          navigateMonth(1);
        }, 100);
      }
    }
  };

  // Find the next upcoming event index for scrolling
  const findUpcomingEventIndex = () => {
    const today = new Date();
    today.setHours(0, 0, 0, 0);
    
    // Check for current month
    const isCurrentMonth = 
      today.getMonth() === currentDate.getMonth() && 
      today.getFullYear() === currentDate.getFullYear();
    
    if (!isCurrentMonth || events.length === 0) {
      return -1;
    }
    
    // Find the next upcoming event
    for (let i = 0; i < events.length; i++) {
      const eventStart = new Date(events[i].startDate);
      eventStart.setHours(0, 0, 0, 0);
      
      if (eventStart >= today) {
        return i; // Found the first upcoming event
      }
    }
    
    return -1; // No upcoming events found
  };

  useEffect(() => {
    fetchEvents(currentDate);
  }, [currentDate, userId, token]);

  // Scroll to the upcoming event after events are loaded
  useEffect(() => {
    if (!isLoading && events.length > 0 && eventsContainerRef.current) {
      const upcomingIndex = findUpcomingEventIndex();
      
      if (upcomingEventRef.current && upcomingIndex >= 0) {
        upcomingEventRef.current.scrollIntoView({ behavior: 'smooth', block: 'start' });
      }
    }
  }, [isLoading, events]);

  const formatDate = (date) => {
    return date.toISOString().split('T')[0];
  };

  const formatEventDate = (startDate, endDate) => {
    const start = new Date(startDate);
    const end = new Date(endDate);
    
    if (start.toDateString() === end.toDateString()) {
      return `${start.getDate()} ${start.toLocaleDateString('nl-NL', { month: 'long' })}`;
    }
    
    if (start.getMonth() === end.getMonth()) {
      return `${start.getDate()} - ${end.getDate()} ${start.toLocaleDateString('nl-NL', { month: 'long' })}`;
    }
    
    return `${start.getDate()} ${start.toLocaleDateString('nl-NL', { month: 'long' })} - ${end.getDate()} ${end.toLocaleDateString('nl-NL', { month: 'long' })}`;
  };

  const formatEventTime = (dateString) => {
    return new Date(dateString).toLocaleTimeString('nl-NL', { 
      hour: '2-digit', 
      minute: '2-digit' 
    });
  };

  const navigateMonth = (direction) => {
    setCurrentDate(new Date(currentDate.getFullYear(), currentDate.getMonth() + direction, 1));
  };

  const openEventDetails = (group) => {
    setSelectedEvent(group);
    setShowModal(true);
  };

  return (
    <div className="planning-container">
      <div className="planning-header">
        <h1 className="planning-title">Planning</h1>
        <div className="month-navigation">
          <button onClick={() => navigateMonth(-1)} className="nav-button">
            ←
          </button>
          <h2>
            {currentDate.toLocaleDateString('nl-NL', { 
              month: 'long', 
              year: 'numeric' 
            })}
          </h2>
          <button onClick={() => navigateMonth(1)} className="nav-button">
            →
          </button>
        </div>
      </div>

      {isLoading ? (
        <div className="loading-spinner">Laden...</div>
      ) : (
        <div className="events-timeline" ref={eventsContainerRef}>
          {events.length === 0 ? (
            <div className="no-events">
              Geen planning gevonden voor deze maand
            </div>
          ) : (
            events.map((group, index) => {
              // Set the first event of April as the upcoming event
              const isFirstOfApril = 
                currentDate.getMonth() === 3 && // April (0-indexed)
                index === 0 && 
                initialLoad === false;
                
              // Either use the real next event logic or the April-specific logic
              const isUpcoming = isFirstOfApril;
              
              return (
                <div 
                  key={group.mainEvent.id} 
                  className={`event-card ${isUpcoming ? 'upcoming-event' : ''}`}
                  ref={isUpcoming ? upcomingEventRef : null}
                  style={{
                    borderLeftColor: group.mainEvent.color || '#3b82f6',
                    position: 'relative'
                  }}
                  onClick={() => openEventDetails(group)}
                >
                  {/* Direct VOLGENDE badge */}
                  {isUpcoming && (
                    <div 
                      style={{
                        position: 'absolute',
                        top: 0,
                        right: 0,
                        fontSize: '10px',
                        fontWeight: 600,
                        letterSpacing: '0.5px',
                        textTransform: 'uppercase',
                        color: '#ffffff',
                        backgroundColor: '#3b82f6',
                        padding: '4px 8px',
                        borderRadius: '0 8px 0 8px',
                        zIndex: 9999
                      }}
                    >
                      VOLGENDE
                    </div>
                  )}
                  <div className="event-grid">
                    <div className="event-date">
                      {formatEventDate(group.startDate, group.endDate)}
                    </div>
                    <div className="event-time">
                      {formatEventTime(group.mainEvent.start)} - {formatEventTime(group.mainEvent.end)}
                    </div>
                    <div className="event-title-container">
                      <div className="event-title">
                        {group.mainEvent.title}
                      </div>
                    </div>
                    {group.subEvents.length > 0 && (
                      <div className="event-subitems">
                        {group.subEvents.map(subEvent => (
                          <div key={subEvent.id} className="event-subitem">
                            • {subEvent.title}
                          </div>
                        ))}
                      </div>
                    )}
                  </div>
                </div>
              );
            })
          )}
        </div>
      )}

      {showModal && selectedEvent && (
        <div className="modal-overlay" onClick={() => setShowModal(false)}>
          <div className="modal-content" onClick={e => e.stopPropagation()}>
            <div className="modal-header" style={{ 
              borderBottom: `3px solid ${selectedEvent.mainEvent.color || '#3b82f6'}`
            }}>
              <h3>{selectedEvent.mainEvent.title}</h3>
              <button className="modal-close" onClick={() => setShowModal(false)}>×</button>
            </div>
            <div className="modal-body">
              <div className="modal-info-row">
                <strong>Datum:</strong> 
                {formatEventDate(selectedEvent.mainEvent.start, selectedEvent.mainEvent.end)}
              </div>
              <div className="modal-info-row">
                <strong>Tijd:</strong> 
                {formatEventTime(selectedEvent.mainEvent.start)} - {formatEventTime(selectedEvent.mainEvent.end)}
              </div>
              {selectedEvent.mainEvent.reason && (
                <div className="modal-info-row">
                  <strong>Event:</strong>
                  {selectedEvent.mainEvent.reason}
                </div>
              )}
              {selectedEvent.mainEvent.description && (
                <div className="modal-info-row">
                  <strong>Omschrijving:</strong>
                  {selectedEvent.mainEvent.description}
                </div>
              )}
              {selectedEvent.subEvents?.[0]?.MW && (
                <div className="modal-info-row">
                  <strong>Team:</strong>
                  <ul className="team-list">
                    {selectedEvent.subEvents[0].MW.split('\r')
                      .filter(item => item.trim() !== '')
                      .map((member, index) => (
                        <li key={index} className="team-member">{member}</li>
                      ))}
                  </ul>
                </div>
              )}
            </div>
          </div>
        </div>
      )}
    </div>
  );
}

export default Planning;