import React, { useState, useEffect } from "react";
import axios from "axios";
import "./Dashboard.css";

function Dashboard({ username, token, setToken, onTokenError }) {
  const [monthlyData, setMonthlyData] = useState([]);
  const [plannedEvents, setPlannedEvents] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [totalMonthHours, setTotalMonthHours] = useState(0);
  const [weeklyTotal, setWeeklyTotal] = useState(0);
  const [fetchingToken, setFetchingToken] = useState(false);
  const [selectedEvent, setSelectedEvent] = useState(null);
  const [showModal, setShowModal] = useState(false);

  const formatDate = (date) => {
    const year = date.getFullYear();
    const month = String(date.getMonth() + 1).padStart(2, '0');
    const day = String(date.getDate()).padStart(2, '0');
    return `${year}-${month}-${day}`;
  };

  const formatTimeValue = (value) => String(value).padStart(2, '0');

  const formatTimeToHHMM = (hours) => {
    if (!hours || hours <= 0) return "0:00";
    const wholeHours = Math.floor(hours);
    const decimalPart = hours - wholeHours;
    
    // Convert decimal part to minutes
    let minutes = 0;
    if (decimalPart === 0.25) minutes = 15;
    if (decimalPart === 0.5) minutes = 30;
    if (decimalPart === 0.75) minutes = 45;
    
    return `${wholeHours}:${String(minutes).padStart(2, '0')}`;
  };

  const formatEventDate = (startDate, endDate) => {
    const start = new Date(startDate);
    const end = new Date(endDate);
    
    if (start.toDateString() === end.toDateString()) {
      return `${start.getDate()} ${start.toLocaleDateString('nl-NL', { month: 'long' })}`;
    }
    
    if (start.getMonth() === end.getMonth()) {
      return `${start.getDate()} - ${end.getDate()} ${start.toLocaleDateString('nl-NL', { month: 'long' })}`;
    }
    
    return `${start.getDate()} ${start.toLocaleDateString('nl-NL', { month: 'long' })} - ${end.getDate()} ${end.toLocaleDateString('nl-NL', { month: 'long' })}`;
  };

  const formatEventTime = (dateString) => {
    return new Date(dateString).toLocaleTimeString('nl-NL', { 
      hour: '2-digit', 
      minute: '2-digit' 
    });
  };
 

  const processPlannedEvents = (events) => {
    const groupEvents = (events) => {
      const sortedEvents = [...events].sort((a, b) => new Date(a.start) - new Date(b.start));
      const grouped = [];
      let currentGroup = null;
      
      sortedEvents.forEach(event => {
        const eventTime = new Date(event.start);
        
        if (!currentGroup) {
          currentGroup = {
            ...event,
            subEvents: [],
            startDate: new Date(event.start),
            endDate: new Date(event.end)
          };
          grouped.push(currentGroup);
        } else {
          const timeDiff = (eventTime - new Date(currentGroup.start)) / 1000 / 60;
          
          if (timeDiff <= 2 && timeDiff > 0) {
            currentGroup.subEvents.push(event);
            if (new Date(event.end) > currentGroup.endDate) {
              currentGroup.endDate = new Date(event.end);
            }
          } else {
            currentGroup = {
              ...event,
              subEvents: [],
              startDate: new Date(event.start),
              endDate: new Date(event.end)
            };
            grouped.push(currentGroup);
          }
        }
      });
      
      return grouped;
    };

    return groupEvents(
      events.filter(event => new Date(event.start) >= new Date())
    ).slice(0, 5);
  };

  const [selectedDayHours, setSelectedDayHours] = useState(null);

  const processHoursData = (hours) => {
    const processedHours = [];
    
    hours.forEach(entry => {
      const startTime = new Date(`2000-01-01T${entry.startTime}`);
      const endTime = new Date(`2000-01-01T${entry.endTime}`);
      
      // If end time is less than start time, it's an overnight entry
      if (endTime < startTime) {
        // Add 1 day to end time for calculation
        endTime.setDate(endTime.getDate() + 1);
      }
      
      // Calculate duration in hours
// Calculate duration in hours, deducting pause time
const duration = ((endTime - startTime) / (1000 * 60 * 60)) - (entry.pauze / 60);
      
      // If it's an overnight entry
      if (entry.endTime.startsWith('00:') || (startTime > endTime)) {
        const midnightToday = new Date(startTime);
        midnightToday.setHours(23, 59, 59);
        
        const totalDuration = ((endTime - startTime) / (1000 * 60 * 60)) - (entry.pauze / 60);
        const hoursUntilMidnight = ((midnightToday - startTime) / (1000 * 60 * 60));
        const hoursAfterMidnight = totalDuration - hoursUntilMidnight;
        
        // Entry for current day
        processedHours.push({
          ...entry,
          date: entry.date,
          startTime: entry.startTime,
          endTime: '23:59',
          hours: hoursUntilMidnight
        });
        
        // Entry for next day
        const nextDate = new Date(entry.date);
        nextDate.setDate(nextDate.getDate() + 1);
        
        processedHours.push({
          ...entry,
          date: formatDate(nextDate),
          startTime: '00:00',
          endTime: entry.endTime,
          hours: hoursAfterMidnight
        });
      } else {
        processedHours.push({
          ...entry,
          hours: duration
        });
      }
    });
  
    // Group by date
    const dailyData = processedHours.reduce((acc, entry) => {
      if (!acc[entry.date]) {
        acc[entry.date] = {
          date: entry.date,
          hours: 0,
          entries: []
        };
      }
      acc[entry.date].entries.push(entry);
      acc[entry.date].hours += entry.hours;
      return acc;
    }, {});
  
    // Calculate weekly total
    const today = new Date();
    const startOfWeek = new Date(today);
    startOfWeek.setDate(today.getDate() - today.getDay() + 1); // Start from Monday
    startOfWeek.setHours(0, 0, 0, 0);
    
    const endOfWeek = new Date(today);
    endOfWeek.setDate(today.getDate() - today.getDay() + 5); // End on Friday
    endOfWeek.setHours(23, 59, 59, 999);
  
    const weeklyTotal = Object.values(dailyData).reduce((total, day) => {
      const dayDate = new Date(day.date);
      if (dayDate >= startOfWeek && dayDate <= endOfWeek) {
        return total + day.hours;
      }
      return total;
    }, 0);
  
    // Sort entries within each day
    Object.values(dailyData).forEach(day => {
      day.entries.sort((a, b) => {
        const timeA = a.startTime.split(':').map(Number);
        const timeB = b.startTime.split(':').map(Number);
        return (timeA[0] * 60 + timeA[1]) - (timeB[0] * 60 + timeB[1]);
      });
      day.hours = parseFloat(day.hours);
    });
  
    return {
      dailyData: Object.values(dailyData),
      total: parseFloat(processedHours.reduce((sum, entry) => sum + entry.hours, 0)),
      weeklyTotal: parseFloat(weeklyTotal)
    };
  };
  
  // Modal component for showing all hours
  const HoursModal = ({ day, onClose }) => {
    if (!day) return null;
  
    return (
      <div className="hours-modal-overlay" onClick={onClose}>
        <div className="hours-modal-content" onClick={e => e.stopPropagation()}>
          <div className="hours-modal-header">
            <div className="hours-modal-title">
              {new Date(day.date).toLocaleDateString('nl-NL', { 
                day: 'numeric',
                month: 'long'
              })} - {formatTimeToHHMM(day.hours)}
            </div>
            <button className="hours-modal-close" onClick={onClose}>×</button>
          </div>
          <div className="hours-list">
            {day.entries.map((entry, index) => (
              <div key={index} className="hours-entry">
                <div className="hours-time">
                  {entry.startTime} - {entry.endTime}
                </div>
                {entry.project && (
                  <div className="hours-info">
                    {entry.project}
                  </div>
                )}
                                {entry.werkzaamheden && (
                  <div className="hours-werk">
                    {entry.werkzaamheden}
                  </div>
                )}
              </div>
            ))}
          </div>
        </div>
      </div>
    );
  };
  
  // Update the calendar day rendering to include the click handler
  const renderCalendarDay = (day, index) => (
    <div 
      key={index} 
      className={`calendar-day ${day.isEmpty ? 'empty' : day.status}`}
      onClick={() => !day.isEmpty && day.hours > 0 && setSelectedDayHours(day)}
    >
      {!day.isEmpty && (
        <>
          <div className="day-number">{day.date}</div>
          {day.hours > 0 && (
            <div className="day-content">
              <div className="day-hours">{formatTimeToHHMM(day.hours)}</div>
              {day.entries.length > 0 && (
                <div className="day-entries">
                  {day.entries.slice(0, 3).map((entry, i) => (
                    <div key={i} className="time-entry">
                      {entry.startTime} - {entry.endTime}
                    </div>
                  ))}
                  {day.entries.length > 3 && (
                    <div className="more-entries">
                      +{day.entries.length - 3} meer
                    </div>
                  )}
                </div>
              )}
            </div>
          )}
        </>
      )}
    </div>
  );

  useEffect(() => {
    const fetchDashboardData = async (authToken) => {
      try {
        setLoading(true);
        setError(null);
        const today = new Date();
        const hoursResponse = await axios.get("https://fmhyper.com/filemaker-api.php", {
          params: {
            action: "get-all-hours",
            token: authToken,
            userId: username,
            date: formatDate(today)
          }
        }).catch(error => {
          // Specific error handling for hours retrieval
          if (error.response && (error.response.status === 401 || error.response.status === 403)) {
            throw new Error('Token expired');
          }
          throw error;
        });

        if (hoursResponse.data.success) {
          const hours = hoursResponse.data.hours || [];
          const processedHours = processHoursData(hours);
        //   console.log(processedHours);
          setMonthlyData(processedHours.dailyData);
          setTotalMonthHours(processedHours.total);
          setWeeklyTotal(processedHours.weeklyTotal);
        }


        let startDate = new Date(today.getFullYear(), today.getMonth(), 1);
        let endDate = new Date(today.getFullYear(), today.getMonth() + 2, 0);

        const currentMonthResponse = await axios.get("https://fmhyper.com/filemaker-api.php", {
          params: {
            action: "get-planning",
            token: authToken,
            userId: username,
            startDate: formatDate(startDate),
            endDate: formatDate(endDate)
          }
        }).catch(error => {
          // Specific error handling for planning retrieval
          if (error.response && (error.response.status === 401 || error.response.status === 403)) {
            throw new Error('Token expired');
          }
          throw error;
        });

        let events = [];
        if (currentMonthResponse.data.success) {
          events = currentMonthResponse.data.events || [];
          
          if (!events.some(event => new Date(event.start) >= today)) {
            startDate = new Date(today.getFullYear(), today.getMonth() + 1, 1);
            endDate = new Date(today.getFullYear(), today.getMonth() + 2, 0);

            const nextMonthResponse = await axios.get("https://fmhyper.com/filemaker-api.php", {
              params: {
                action: "get-planning",
                token: authToken,
                userId: username,
                startDate: formatDate(startDate),
                endDate: formatDate(endDate)
              }
            }).catch(error => {
              // Specific error handling for next month planning retrieval
              if (error.response && (error.response.status === 401 || error.response.status === 403)) {
                throw new Error('Token expired');
              }
              throw error;
            });

            if (nextMonthResponse.data.success) {
              events = nextMonthResponse.data.events || [];
            }
          }
        }

        const upcomingEvents = processPlannedEvents(events);
        setPlannedEvents(upcomingEvents);

      } catch (err) {
        console.error("Dashboard error:", err);
        
        // Check for token-related errors
        if (err.message === 'Token expired' || 
            (err.response && (err.response.status === 401 || err.response.status === 403))) {
          // Call the token error handler passed from parent
          if (onTokenError) {
            onTokenError();
          }
          setError("Uw sessie is verlopen. Log opnieuw in.");
          return;
        }

        setError(err.message || "Er is een fout opgetreden");
      } finally {
        setLoading(false);
      }
    };

    const getNewToken = async () => {
      try {
        setFetchingToken(true);
        console.log("Fetching new FileMaker token...");

        const response = await axios.post("https://fmhyper.com/filemaker-get-token.php");

        if (response.data.success) {
          const newToken = response.data.tokenAgenda;
          console.log("New Token Received:", newToken);

          if (setToken) {
            setToken(newToken);
            localStorage.setItem("token", newToken);
            fetchDashboardData(newToken);
          } else {
            console.error("setToken is undefined, cannot set the new token.");
            // Call token error handler if setToken is not available
            if (onTokenError) {
              onTokenError();
            }
          }
        } else {
          console.error("Failed to get a new token:", response.data.message);
          // Call token error handler if token request fails
          if (onTokenError) {
            onTokenError();
          }
        }
      } catch (error) {
        console.error("Error requesting new token:", error);
        // Call token error handler if token request fails
        if (onTokenError) {
          onTokenError();
        }
      } finally {
        setFetchingToken(false);
      }
    };

    if (!token) {
      getNewToken();
    } else {
      fetchDashboardData(token);
    }
  }, [token, username, setToken, onTokenError]);

  // Update the renderMonthCalendar function to show detailed time entries


  const calculateHoursBetweenTimes = (start, end) => {
    const [startHour, startMin] = start.split(':').map(Number);
    const [endHour, endMin] = end.split(':').map(Number);
    
    let hours = endHour - startHour;
    let minutes = endMin - startMin;
    
    if (minutes < 0) {
      hours -= 1;
      minutes += 60;
    }
    
    return hours + (minutes / 60);
  };

  const renderMonthCalendar = () => {
    const today = new Date();
    const firstDay = new Date(today.getFullYear(), today.getMonth(), 1);
    const lastDay = new Date(today.getFullYear(), today.getMonth() + 1, 0);
    const firstDayOfWeek = firstDay.getDay() || 7; // 1-7 (Monday-Sunday)
    const days = [];
    
    // Add empty cells for days before the first of the month
    for (let i = 1; i < firstDayOfWeek; i++) {
      days.push({ isEmpty: true });
    }
    
    // Create array of all days in month
    for (let d = 1; d <= lastDay.getDate(); d++) {
      const date = new Date(today.getFullYear(), today.getMonth(), d);
      const dateStr = formatDate(date);
      const dayData = monthlyData.find(data => data.date === dateStr);
      
      const isWeekend = date.getDay() === 0 || date.getDay() === 6;
      const isFriday = date.getDay() === 5;
      const hours = dayData ? dayData.hours : 0;
      
      let status = 'no-hours';
      if (isWeekend) {
        status = 'weekend';
      } else if (hours > 0) {
        if (isFriday) {
          status = hours >= 5 ? 'complete' : 'incomplete';
        } else {
          status = hours >= 8 ? 'complete' : 'incomplete';
        }
      }
      
      days.push({
        date: d,
        dayOfWeek: date.getDay(),
        hours,
        status,
        isEmpty: false,
        entries: dayData ? dayData.entries || [] : []
      });
    }
  
    return (
      <div className="month-calendar">
        <div className="calendar-header">
          <div>Ma</div>
          <div>Di</div>
          <div>Wo</div>
          <div>Do</div>
          <div>Vr</div>
          <div>Za</div>
          <div>Zo</div>
        </div>
        <div className="calendar-grid">
          {days.map((day, index) => (
            <div 
              key={index} 
              className={`calendar-day ${day.isEmpty ? 'empty' : day.status}`}
              onClick={() => !day.isEmpty && day.hours > 0 && setSelectedDayHours({
                ...day,
                date: formatDate(new Date(today.getFullYear(), today.getMonth(), day.date))
              })}
            >
              {!day.isEmpty && (
                <>
<div className="day-number">
  {new Date(today.getFullYear(), today.getMonth(), day.date).toLocaleDateString('nl-NL', {
    day: 'numeric',
    month: 'numeric'
  })}
</div>
                  {day.hours > 0 && (
                    <div className="day-content">
                      <div className="day-hours">{formatTimeToHHMM(day.hours)}</div>
                      {day.entries.length > 0 && (
                        <div className="day-entries">
                          {/* {day.entries.slice(0, 3).map((entry, i) => (
                            <div key={i} className="time-entry">
                              {entry.startTime} - {entry.endTime}
                            </div>
                          ))}
                          {day.entries.length > 3 && (
                            <div className="more-entries">
                              +{day.entries.length - 3} meer
                            </div>
                          )} */}
                        </div>
                      )}
                    </div>
                  )}
                </>
              )}
            </div>
          ))}
        </div>
      </div>
    );
  };

  if (error) {
    return (
      <div className="dashboard-error">
        <h3>⚠️ Error</h3>
        <p>{error}</p>
      </div>
    );
  }

  return (
    <div className="dashboard-container">
      <div className="dashboard-welcome">
        <div className="welcome-content">
          <h1>Welkom, {username}</h1>
        </div>
      </div>

      <div className="stats-grid">
        <div className="stat-card total-hours">
          <div className="stat-icon">⏱️</div>
          <div className="stat-content">
            <h3>Totaal Uren Deze Maand</h3>
            <div className="stat-value">{formatTimeToHHMM(totalMonthHours)}</div>
            <div className="stat-label">uren</div>
          </div>
        </div>

        <div className="stat-card weekly-hours">
          <div className="stat-icon">📅</div>
          <div className="stat-content">
            <h3>Geregistreerde Uren Deze Week</h3>
            <div className="stat-value">{formatTimeToHHMM(weeklyTotal)}</div>
            <div className="stat-label">uren</div>
          </div>
        </div>
      </div>

      <div className="dashboard-grid">
        <div className="grid-card hours-overview">
          <h2>Urenregistratie Overzicht</h2>
          {renderMonthCalendar()}
        </div>

        <div className="grid-card upcoming-events">
          <h2>Aankomende Events</h2>
          <div className="events-list">
            {plannedEvents.length > 0 ? (
              plannedEvents.map((event) => (
                <div 
                  key={event.id} 
                  className="event-card"
                  style={{
                    borderLeftColor: event.color || '#3b82f6'
                  }}
                  onClick={() => {
                    setSelectedEvent(event);
                    setShowModal(true);
                    console.log(event)
                  }}
                >
                  <div className="event-grid">
                    <div className="event-date">
                      {formatEventDate(event.start, event.end)}
                    </div>
                    <div className="event-time">
                      {formatEventTime(event.start)} - {formatEventTime(event.end)}
                    </div>
                    <div className="event-title-container">
                      <div className="event-title">
                        {event.title}
                      </div>
                      {/* {event.reason && (
                        <div className="event-reason">
                          {event.reason}
                        </div>
                      )} */}
                    </div>
                    {event.subEvents && event.subEvents.length > 0 && (
                      <div className="event-subitems">
                        {event.subEvents.map(subEvent => (
                          <div key={subEvent.id} className="event-subitem">
                            • {subEvent.title}
                          </div>
                        ))}
                      </div>
                    )}
                  </div>
                </div>
              ))
            ) : (
              <div className="no-events">
                <p>Geen geplande events</p>
              </div>
            )}
          </div>
        </div>
      </div>

      {showModal && selectedEvent && (
        <div className="modal-overlay" onClick={() => setShowModal(false)}>
          <div className="modal-content" onClick={e => e.stopPropagation()}>
            <div className="modal-header" style={{ 
              borderBottom: `3px solid ${selectedEvent.color || '#3b82f6'}`
            }}>
              <h3>{selectedEvent.title}</h3>
              <button className="modal-close" onClick={() => setShowModal(false)}>×</button>
            </div>
            <div className="modal-body">
              <div className="modal-info-row">
                <strong>Datum:</strong> 
                {formatEventDate(selectedEvent.start, selectedEvent.end)}
              </div>
              <div className="modal-info-row">
                <strong>Tijd:</strong> 
                {formatEventTime(selectedEvent.start)} - {formatEventTime(selectedEvent.end)}
              </div>
              {selectedEvent.reason && (
                <div className="modal-info-row">
                  <strong>Event:</strong>
                  {selectedEvent.reason}
                </div>
              )}
              {selectedEvent.description && (
                <div className="modal-info-row">
                  <strong>Omschrijving:</strong>
                  {selectedEvent.description}
                </div>
              )}
{selectedEvent.subEvents?.[0]?.MW && (
  <div className="modal-info-row">
    <strong>Team:</strong>
    <ul className="team-list">
      {selectedEvent.subEvents[0].MW.split('\r')
        .filter(item => item.trim() !== '')
        .map((member, index) => (
          <li key={index} className="team-member">{member}</li>
        ))}
    </ul>
  </div>
)}
            </div>
          </div>
        </div>
      )}
      {/* Add this at the bottom of your return statement */}
{selectedDayHours && (
  <HoursModal 
    day={selectedDayHours} 
    onClose={() => setSelectedDayHours(null)} 
  />
)}
    </div>
  );
}

export default Dashboard;